<template>
    <Layout title="Willkommen">
        <div class="bg-[url('/img/home-flag-bg.png')] bg-no-repeat bg-cover bg-center p-8 text-white rounded-lg">
            <h3 class="text-3xl font-semibold mb-4">
                Online Fortbildung für Sie </h3>
            <div class="text-2xl mb-8">
                <ul class="list-inside list-disc">
                    <li>Gesetzliche Unterweisungen</li>
                    <li>Fachspezifische Themen</li>
                    <li>Führungskompetenzen</li>
                </ul>
            </div>
            <Link :href="route('registrierung.index')"
                  class="mt-5 inline-flex items-center justify-center rounded-md
                border border-transparent bg-dbblue-500 px-4 py-2 font-medium text-white shadow-sm text-2xl
                 hover:bg-dbblue-600 focus:outline-none focus:ring-2 focus:ring-dbblue-500 focus:ring-offset-2 sm:w-auto"
                  type="button"
            >
                Registrieren
            </Link>
        </div>
    </Layout>
</template>

<script>
import Layout from "../components/layout/LayoutDefault.vue";
import News from "../components/News.vue";
import {Link} from "@inertiajs/vue3";

export default {
    components: {
        Layout,
        News,
        Link
    }
}
</script>
